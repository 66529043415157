import React, { useState } from "react";
import { Button, Container, Paper, TextField, Typography, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../App";

function ForgotPassword({ showChangePassword, setShowChangePassword }) {
    const navigate = useNavigate();
    const user = useUser();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handlePassword = () => {
        const emailRegex = /\b[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}\b/;
        const result = emailRegex.test(email);
    
        if (!email || !password || !confirmPassword) {
            const emptyFields = [];
            if (!email) emptyFields.push("Email");
            if (!password) emptyFields.push("Password");
            if (!confirmPassword) emptyFields.push("Confirm Password");
    
            setError(`${emptyFields.join(", ")} ${emptyFields.length === 1 ? "is" : "are"} empty.`);
            return;
        }
    
        if (!result) {
            setError("Email is incorrect.");
            return;
        }
    
        if (password !== confirmPassword) {
            setError("Password and Confirm Password do not match.");
            return; 
        }
    
        setShowChangePassword(!showChangePassword);
        navigate("/");
    };
    
    return (
        <Container maxWidth="sm" sx={{ position: "relative", zIndex: 1, top: "-70px" }}>
            <Paper elevation={3} sx={{ padding: 3, backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                <Typography variant="h5" gutterBottom align="center">
                    Forgot Password
                </Typography>
                <TextField
                    label="E-mail"
                    type="email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    color="success"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    color="success"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    color="success"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {error && <Typography color="error">{error}</Typography>}
                <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ marginTop: 2 }}
                    onClick={handlePassword}
                >
                    Update Password
                </Button>
            </Paper>
        </Container>
    );
}

export default ForgotPassword;
