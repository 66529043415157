import React, { useState } from "react";
import axios from "axios";
import { Button, Container, Paper, TextField, Typography, Link } from "@mui/material";
import bgImg from "../../assets/img/bgImg.jpg";
import ForgotPassword from "../Routes/ForgotPassword/Forgot-Password";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleShowChangePassword = () => {
    setShowChangePassword(true);
  };

  const loginHandler = async () => {
    if (!email) {
      alert("Enter email");
      return;
    }
    if (!password) {
      alert("Enter Password");
      return;
    }

    try {
      setIsLoading(true);

      const response = await axios.post(
        "https://guards-apis.onrender.com/api/user/login",
        {
          email,
          password,
        }
      );

      localStorage.setItem("auth_token", response.data);
      window.location.reload();
    } catch (err) {
      console.error("error in login: ", err, err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${bgImg})`,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          filter: "blur(5px)",
        }}
      />
      {showChangePassword ? (
        <ForgotPassword setShowChangePassword={setShowChangePassword} showChangePassword={showChangePassword}/>
      ) : (
        <Container maxWidth="sm" style={{ position: "relative", zIndex: 1 }}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h5" gutterBottom align="center">
              Login
            </Typography>
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              variant="outlined"
              color="success"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              fullWidth
              margin="normal"
              type="password"
              variant="outlined"
              color="success"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={loginHandler}
              sx={{ marginTop: 2 }}
            >
              Login
            </Button>
            {/* <Link
              to="/forgot-password"
              style={{
                color: "green",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
                cursor: "pointer",
                textDecoration: "none",
              }}
              onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              onClick={handleShowChangePassword}
            >
              Forgot Password
            </Link> */}
          </Paper>
        </Container>
      )}
    </div>
  );
};

export default LoginPage;
